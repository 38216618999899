import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '@icons/wolfkit-light/arrow-small-right-light.svg';
import { PortfolioCard, useMarketplaceInfo } from '@entities/portfolio';
import { getCurrentMonthText } from '@shared/lib/date-time';
import LazyImgLoader from '@shared/ui/misc/LazyImgLoader';
import { Title as TitleComponent } from '@src/components/styled/Typography';
import ButtonCustom from '@shared/ui/buttons/ButtonCustom';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import QuotationPanel from './components/QuotationPanel';
import backgrounImageUrl from './marketplace-image.png';
const MarketplaceContainer = styled.div(() => ({
    width: 'inherit',
    height: 'inherit',
    position: 'relative',
}));
const MarketplaceInnerContainer = styled.div((props) => ({
    height: 'inherit',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    // 140px
    gap: `${props.theme.spacing_sizes.xs * 17.5}px 0`,
}));
const Title = styled(TitleComponent)(props => ({
    textAlign: 'center',
    color: `${props.theme.palette.text.disabled}`,
    userSelect: 'none',
    marginBottom: '55px',
}));
const FiltersContainer = styled.div(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
}));
const ButtonsContainer = styled.div(props => ({
    display: 'flex',
    gap: `${props.theme.spacing_sizes.xs}px`,
    alignSelf: 'center',
}));
const CardsContainer = styled.div(props => ({
    display: 'flex',
    gap: `${props.theme.spacing_sizes.l}px`,
    flexWrap: 'wrap',
    justifyContent: 'center',
}));
const FilterButton = styled(ButtonCustom)(props => ({
    backgroundColor: `${props.theme.customColors.button.onPrimary}`,
    color: `${props.theme.palette.text.secondary}`,
    '&:hover': {
        backgroundColor: `${props.theme.customColors.button.onPrimary}`,
    },
}));
const ViewListButton = styled(ButtonCustom)(props => ({
    'svg path': {
        fill: `${props.theme.customColors.button.onPrimary}`,
    },
}));
const BackgroundImageContainer = styled.div((props) => ({
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    maxHeight: `${props.theme.spacing_sizes.m * 100}px`,
    backgroundImage: `linear-gradient(to bottom, transparent 75%, #F8F9FA), url(${props.url || ''})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
}));
const BackgroundImage = () => (_jsx(LazyImgLoader, { url: backgrounImageUrl, children: _jsx(BackgroundImageContainer, { url: backgrounImageUrl }) }));
const Marketplace = () => {
    const { quotations, portfolioList } = useMarketplaceInfo();
    const { t, i18n } = useTranslation();
    const { navigate, routes } = useAppNavigation();
    const goToMarketplaceList = () => {
        navigate(routes.MARKETPLACE_LIST);
    };
    return (_jsxs(MarketplaceContainer, { children: [_jsx(BackgroundImage, {}), _jsxs(MarketplaceInnerContainer, { children: [_jsx(QuotationPanel, { quotations: quotations }), _jsxs(FiltersContainer, { children: [_jsx(Title, { children: t('marketplace.main_view.title', { currentMonth: getCurrentMonthText(i18n.language) }) }), _jsxs(ButtonsContainer, { children: [_jsx(FilterButton, { variant: 'outlined', disableElevation: true, disableRipple: true, children: t('marketplace.main_view.filters.all') }), _jsx(FilterButton, { variant: 'outlined', disableElevation: true, disableRipple: true, children: t('marketplace.main_view.filters.spot') }), _jsx(FilterButton, { variant: 'outlined', disableElevation: true, disableRipple: true, children: t('marketplace.main_view.filters.margin') }), _jsx(ViewListButton, { variant: 'contained', endIcon: _jsx(ArrowIcon, {}), onClick: goToMarketplaceList, children: t('marketplace.main_view.filters.see_all') })] })] }), _jsx(CardsContainer, { children: portfolioList === null || portfolioList === void 0 ? void 0 : portfolioList.map((portfolio) => (_jsx(PortfolioCard, { viewType: 'card', portfolio: portfolio }, portfolio.id))) })] })] }));
};
export default Marketplace;
